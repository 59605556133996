import { useParams, useSearchParams } from "react-router-dom";

import { ChatWidgetRawData, generateChatWidgetConfig, IStylesOverrides } from "../../../utils/generateChatWidgetConfig";
import { useGetAmazeKey } from "../../botSettings/hooks";
import { SOCIAL_TYPES } from "../../botSettings/types/enum";
import { BACKEND_URL } from "../constants";
import { useGetCharacterQuery } from "../services/charactersApiSlice";
import { useGetChatWidgetSettingQuery } from "../services/chatWidgetApiSlice";

interface IProps {
    customStyleOverrides?: IStylesOverrides;
    selectorId?: string;
    characterId?: string;
    configForApp?: Partial<ChatWidgetRawData>;
    socialMedia?: SOCIAL_TYPES;
}
// * Restructure the parameters into the format required by chat-widget
const useGenerateChatWidgetConfig = ({
    customStyleOverrides,
    selectorId,
    characterId,
    configForApp,
    socialMedia = SOCIAL_TYPES.autoTest,
}: IProps) => {
    const { projectId } = useParams();
    const [searchParams] = useSearchParams();
    const selectedHintLang = searchParams.get("lang");
    const { data: characterData } = useGetCharacterQuery({ id: characterId as string }, { skip: !characterId });

    const { data: chatWidgetConfigData } = useGetChatWidgetSettingQuery(
        { character_id: characterId as string },
        { skip: !characterId }
    );
    const { amazeKey } = useGetAmazeKey({
        projectId,
        characterId,
        socialMedia: socialMedia,
    });
    if (!characterData?.data?.character || !amazeKey || !BACKEND_URL) return;

    const { primary, ...restScriptContent } = chatWidgetConfigData?.data?.script_content || {};
    const processedData: ChatWidgetRawData = {
        amaze_key: amazeKey,
        chatApiUrl: BACKEND_URL,
        greetingPrompt: characterData?.data?.character?.greeting,
        autoFocus: false,
        mobileAvatarUrl: characterData?.data?.character?.avatar_file_name,
        styleOverrides: primary ? { ...customStyleOverrides, primary } : { ...customStyleOverrides },
        ...(selectedHintLang && { hintLanguage: selectedHintLang }),
        ...restScriptContent,
    };

    const data = configForApp ? { ...processedData, ...configForApp } : processedData;
    return generateChatWidgetConfig(data, selectorId);
};
export default useGenerateChatWidgetConfig;
